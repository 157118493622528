import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";

export const ContactPageTemplate = () => (
  <Container>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <Image src={`${withPrefix('/')}img/wildwood-contact.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">Contact</Heading>
        <Text>Vragen over Wildwood of een houten kerstboom bestellen?<br/> Laat een berichtje achter via de mail: <a href="mailto:info@wildwood.nu">info@wildwood.nu</a> of bel met Herman <a href="tel:+31653240933">0653240933</a> of Steven <a href="tel:+31621152376">0621152376</a> voor meer informatie</Text>
      </Col>
    </Row>
  </Container>
);

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContactPageTemplate
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const ContactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
